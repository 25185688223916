import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import HeroSection from "~sections/LandingPages/Salesforce/HeroSection"
import { Helmet } from "react-helmet"
import Manage from "~sections/LandingPages/Salesforce/Benefits/manage"
import Measure from "~sections/LandingPages/Salesforce/Benefits/Measure"
import Track from "~sections/LandingPages/Salesforce/Benefits/track"
import Enrich from "~sections/LandingPages/Salesforce/Benefits/enrich"
import Overview from "~sections/LandingPages/Zoho/overview"
import Tools from "~sections/LandingPages/Zoho/integration-tools"
import Grad from "~sections/LandingPages/Grad/grad"
import image from "~assets/image/media/Zoho/zoho.png";
import img1 from "~assets/image/media/Zoho/Pic1.png";
import img2 from "~assets/image/media/Zoho/pic2.png";
import img3 from "~assets/image/media/Zoho/pic3.png";
import img4 from "~assets/image/media/Zoho/Pic1.png";

const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Zoho() {
  return (
    <>
    <Helmet>
    <title>We-Connect | Zoho</title>
    <meta name="description" content=""/>
    <meta property="og:title" content=""/>
    <meta property="og:description" content="Streamline and scale your LinkedIn outreach with We-Connect’s native Salesforce integration. Manage LinkedIn activity directly from Salesforce for a unified pipeline. "/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/salesforce-integration"/>

    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="Integrate Salesforce & We-Connect automated LinkedIn outreach "/>
    <meta property="twitter:description" content="Streamline and scale your LinkedIn outreach with We-Connect’s native Salesforce integration. Manage LinkedIn activity directly from Salesforce for a unified pipeline."/>
    <meta property="twitter:image" content="https://we-connect.io/we-connect-salesforce-integration.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <HeroSection title="Automate LinkedIn workflows with our Zoho integration" text="Simplify LinkedIn lead management for small businesses and startups using We-Connect’s native Zoho CRM integration. Sync contacts, track campaigns, and improve automated workflows." img={image}/>
      <Overview />
      <Measure title="Integration features" subtitle="Track campaign performance effortlessly" image={img1} text="Analyze key metrics like connection rates, replies, and link clicks directly within Zoho. Use this data to refine your outreach strategy and drive improved results."/>
      <Track subtitle="Real-time contact updates " image={img2} text="Sync contacts from We-Connect into Zoho and automatically update information like job titles and company details. Create personalized campaigns with insights that matter to your small business."/>
      <Enrich subtitle="Save time on manual tasks" image={img3} text="Focus on growth, not admin work. Automate contact syncing, lead tracking, and campaign notifications to maximize productivity across your entire team. Never let another lead slip through the cracks."/>
      <Manage subtitle="Manage campaigns directly in Zoho (coming soon)" image={img4} text="Handle multi-channel LinkedIn and email campaigns from start to finish directly in Zoho without switching tools or disrupting processes. Build behavior-based lead generation campaigns or marketing campaigns without leaving your CRM."/>
      <Tools />
      <Grad value="Simplified lead management across all channels" text="Integrate with Zoho CRM today"/>
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
