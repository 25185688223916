import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Header";
import favicon from "~assets/image/favicon.png"
import favico from "~assets/image/favicon.ico"
import appleIcon from "~assets/image/apple-touch-icon.png"
import icon16 from "~assets/image/favicon-16x16.png"
import icon32 from "~assets/image/favicon-32x32.png"
import faviconSvg from "~assets/image/favicon.svg"
import icon96 from "~assets/image/favicon-96x96.png"
import appleIcon57 from "~assets/image/apple-touch-icon-57x57.png"
import appleIcon72 from "~assets/image/apple-touch-icon-72x72.png"
import appleIcon114 from "~assets/image/apple-touch-icon-114x114.png"
import appleIcon144 from "~assets/image/apple-touch-icon-144x144.png"

export default function Layout({
  children,
}) {
  return (
    <>
    <Helmet>
          <title>We-Connect | The Safest Linkedin Automation Software</title>
          <link rel="icon" type="image/png" href={favicon} />
          <link rel="apple-touch-icon-precomposed" type="image/png" href={appleIcon}/>
          <link rel="icon" type="image/png" href={icon16} sizes="16x16" />
          <link rel="icon" type="image/png" href={icon32} sizes="32x32" />
          <link rel="icon" type="image/png" href={icon96} sizes="96x96" />
          <link rel="icon" type="image/png" href={appleIcon57} sizes="57x57" />
          <link rel="icon" type="image/png" href={appleIcon72} sizes="72x72" />
          <link rel="icon" type="image/png" href={appleIcon114} sizes="114x114" />
          <link rel="icon" type="image/png" href={appleIcon144} sizes="144x144" />
          <link rel="icon" type="image/svg+xml" href={faviconSvg} />
          <meta name="msapplication-TileColor" content="#FFFFFF" />
          <meta name="msapplication-TileImage" content="mstile-144x144.png" />
          <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
          <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
          <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
          <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />
          <link rel="shortcut icon" href={favico} />
          <meta name="description" content="Elevate your lead generation game using We-Connect, the safest cloud-based LinkedIn outreach platform that helps you easily connect with more prospects through automation, personalization, and analytics. Try our 14-days free trial."/>
          <meta property="og:title" content="We-Connect | The most advanced and affordable LinkedIn outreach platform."/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://we-connect.io"/>
          <meta property="og:image" content="https://we-connect.io/we-connect_home-page.png"/>
          <meta property="og:description" content="Elevate your lead generation game using We-Connect, the safest cloud-based LinkedIn outreach platform that helps you easily connect with more prospects through automation, personalization, and analytics. Try our 14-days free trial."/>
          <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
          <meta property="twitter:title" content="We-Connect | The most advanced and affordable LinkedIn outreach platform."/>
          <meta property="twitter:image" content="https://we-connect.io/we-connect_home-page.png"/>
          <meta property="twitter:description" content="Elevate your lead generation game using We-Connect, the safest cloud-based LinkedIn outreach platform that helps you easily connect with more prospects through automation, personalization, and analytics. Try our 14-days free trial."/>
    </Helmet>
      <Header/>
      {children}
    </>
  )
}