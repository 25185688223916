import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Content from '../HubSpot/Benefits/style'
export default function Overview(){
return(
<Content>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xxl-12 col-xl-12 col-lg-12">
        <Content.Content>
          
          <Content.ContentBox>
            <Content.TitleSmall as="h3">Streamline your LinkedIn sales outreach with Zoho integration.</Content.TitleSmall>
            <Content.Text>• <b>Contact management</b>: Sync LinkedIn leads and update contact information automatically in Zoho.</Content.Text>
            <Content.Text>• <b>Campaign reporting</b>: Track campaign performance metrics like replies and connection rates in Zoho.</Content.Text>
            <Content.Text>• <b>Real-time notifications</b>: Receive real-time notifications on key campaign events.</Content.Text>
            <Content.Text>• <b>Automated workflows</b>: Personalize outreach efforts with enriched contact insights for better engagement.</Content.Text>
          </Content.ContentBox>
          
        </Content.Content>
      </Col>
    </Row>
  </Container>
</Content>

)
}