import React from "react"
import { Navbar } from "react-bootstrap"
import siteBrandDark from "~image/logo/logo.svg"
import siteBrandLight from "~image/logo/logo.svg"
import Menu from "./Menu"
import { AnchorLink } from "gatsby-plugin-anchor-links";
const SiteNavbar = ({buttonBlock,darkLogo,customLogo,defaultLogo}) => {

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="site-navbar"
      >
      
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="hamburgur"
        />
        <Navbar.Brand className="brand-logo">
        <AnchorLink to="/">

          {defaultLogo ? (<img src={defaultLogo} alt="site-brand" height="35 px"/>) : customLogo ? (
            <img src={customLogo} alt="site-brand" height="35 px"/>
          ) : (
            <img src={darkLogo ? siteBrandDark : siteBrandLight} alt="site-brand" height="35 px"/>
          )}
        </AnchorLink>
        </Navbar.Brand>
        <Menu className="justify-content-center" />
        {buttonBlock}
      </Navbar>
    </>
  )
}

export default SiteNavbar
