import React from 'react'
import Hero from './style'
import { Col, Container, Row } from 'react-bootstrap';
import imageOne from "~assets/image/media/Pipedrive/pipedrive.png";
export default function HeroSection(){
  return(
<Hero className="position-relative bg-default" backgroundColor="#F3F4F6">
  <Container>
  <Row>
      <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-11 order-2 order-lg-1"
              xs="12">
        <Hero.ContentBlock mb="35px">
        <Hero.Title as="h1">
        Streamline LinkedIn lead generation in Pipedrive
        </Hero.Title>
                <Hero.Text>
                Drive more conversions with seamless LinkedIn outreach integration designed for SMB sales teams using Pipedrive.<br className="d-none d-xs-block" /> 
                </Hero.Text>
        <Hero.Button className="btn btn-primary text-white mt-5 mb-5" target="_blank" to="//account.we-connect.io/signup" sizeX="380px" sizeY="56px">Sign in to install app</Hero.Button>
        </Hero.ContentBlock>
        
        
      </Col>
      <Col xs="14" className="col-xl-6 col-lg-6 col-md-8 col-xs-11 order-1 order-lg-2">
        <Hero.Image>
        <img className="w-100" src={imageOne} alt="content" layout="fullWidth" placeholder="blurred" />
        </Hero.Image>
      </Col>
    </Row>
  </Container>
  
</Hero>
)
}