import React, { useState } from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from '../../Sales/Styles/styleTwo'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/media/Hubspot/pipedrive.svg";
import imageThree from "~assets/image/media/Hubspot/salesforce.svg";
import imageFour from "~assets/image/media/Hubspot/zapier.svg";
import imgTwo from "~assets/image/media/Hubspot/hubspot-icon.svg";
import { Link } from 'gatsby';

export default function Tools({}){
    const styles = {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '35px',
        marginBottom: '10px',
   };
   const zapier = {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '10px',
};

const hubspot = {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '5px',
    padding: '20px',
    marginBottom: '10px',
};
return(
<Content backgroundColor="#F3F4F6">
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center">
    <Col xs="12" className="col-xl-3 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={zapier}>
        <Row>
        <Col ><img className="w-100" src={image} alt="icon" layout="fullWidth" /></Col>
        <Col ><Content.ToolsTitle> <SuperTag value="Pipedrive"/> </Content.ToolsTitle></Col>
        </Row>
        <Content.ToolsText className="pl-3"> <SuperTag value="Integrate We-Connect with Pipedrive."/> </Content.ToolsText>
        <Content.ToolsText className='d-flex justify-content-start'><Link to='/pipedrive'>Learn more</Link></Content.ToolsText>
      </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-3 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={zapier}>
        <Row>
        <Col ><Content.Logo><img className="w-100" src={imgTwo} alt="icon" layout="fullWidth" /></Content.Logo></Col>
        <Col><Content.ToolsTitle> <SuperTag value="Hubspot"/></Content.ToolsTitle>
        </Col>
        </Row>
        <Content.ToolsText className="pl-3"> <SuperTag value="Integrate We-Connect with Hubspot."/> </Content.ToolsText>
      <Content.ToolsText className='d-flex justify-content-start'><Link to='/hubspot-integration'>Learn more</Link></Content.ToolsText>
      </Content.TextBlock>
      
      </Col>
      <Col xs="12" className="col-xl-3 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={zapier}>
        <Row>
        <Col><img className="w-50" src={imageThree} alt="icon" layout="fullWidth" /></Col>
        <Col><Content.ToolsTitle> <SuperTag value="Salesforce"/> </Content.ToolsTitle></Col>
        </Row>
      <Content.ToolsText className="pl-3"> <SuperTag value="Integrate We-Connect with Salesforce."/> </Content.ToolsText>
        <Content.ToolsText className='d-flex justify-content-start'><Link to='/salesforce-integration'>Learn more</Link></Content.ToolsText>
      </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-3 col-lg-6 col-md-8 col-xs-10">
      <Content.TextBlock style={zapier}>
        <Row>
        <Col><img className="w-50" src={imageFour} alt="icon" layout="fullWidth" /></Col>
        <Col><Content.ToolsTitle> <SuperTag value="Zapier"/> </Content.ToolsTitle></Col>
        </Row>
      <Content.ToolsText className="pl-3">Integrate We-Connect with <br className="break" /> Zapier.</Content.ToolsText>
      <Content.ToolsText><Link to='https://zapier.com/app/login?next=/developer/public-invite/149418/1e64204a69a34294a6b544c20f6bc625/' target="_blank">Connect <i class="bi bi-box-arrow-up-right"></i></Link></Content.ToolsText>
      </Content.TextBlock>
      
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>


)
}