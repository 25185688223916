import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import HeroSection from "~sections/LandingPages/Salesforce/HeroSection"
import { Helmet } from "react-helmet"
import Manage from "~sections/LandingPages/HubSpot/Benefits/manage"
import Measure from "~sections/LandingPages/HubSpot/Benefits/Measure"
import Track from "~sections/LandingPages/HubSpot/Benefits/track"
import Enrich from "~sections/LandingPages/HubSpot/Benefits/enrich"
import Overview from "~sections/LandingPages/Salesforce/Benefits/overview"
import Tools from "~sections/LandingPages/Salesforce/Benefits/integration-tools"
import Grad from "~sections/LandingPages/Grad/grad"
import image from "~assets/image/media/Salesforce/salesforce.png";
import measure from "~assets/image/media/Salesforce/Salesforce-Feature1.png";
import track from "~assets/image/media/Salesforce/Salesforce-Feature2.png";
import enrich from "~assets/image/media/Salesforce/Salesforce-Feature3.png";
import manage from "~assets/image/media/Salesforce/Salesforce-Feature4.png";

const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Salesforce() {
  return (
    <>
    <Helmet>
    <title>We-Connect | Salesforce Integration</title>
    <meta name="description" content="Streamline and scale your LinkedIn outreach with We-Connect’s native Salesforce integration. Manage LinkedIn activity directly from Salesforce for a unified pipeline."/>
    <meta property="og:title" content="Integrate Salesforce & We-Connect automated LinkedIn outreach "/>
    <meta property="og:description" content="Streamline and scale your LinkedIn outreach with We-Connect’s native Salesforce integration. Manage LinkedIn activity directly from Salesforce for a unified pipeline. "/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/salesforce-integration"/>

    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="Integrate Salesforce & We-Connect automated LinkedIn outreach "/>
    <meta property="twitter:description" content="Streamline and scale your LinkedIn outreach with We-Connect’s native Salesforce integration. Manage LinkedIn activity directly from Salesforce for a unified pipeline."/>
    <meta property="twitter:image" content="https://we-connect.io/we-connect-salesforce-integration.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <HeroSection title="Automated LinkedIn outreach, now in Salesforce" text="Align your marketing outreach across all channels with our native Salesforce integration. Track campaign progress, enrich your contact database, and analyze results directly from Salesforce." img={image}/>
      <Overview />
      <Measure title="Integration features" subtitle="Easily enrich your contact database" image={measure} text="Never lose track of important prospects again. Automatically sync contact details between We-Connect and Salesforce, ensuring you always have the most up-to-date information, including emails, phone numbers, and LinkedIn connections—all in one place."/>
      <Track subtitle="Get real-time campaign insights" text="No more guesswork—track key LinkedIn outreach metrics like connection rates, reply rates, and engagement, directly from your Salesforce dashboard. Monitor campaign performance in real-time and make data-driven decisions to optimize your outreach strategy." image={track}/>
      <Enrich subtitle="Stay on top of your outreach with alerts" text="Stay in the loop with real-time updates on your LinkedIn outreach. Receive notifications for accepted connection requests, replies, and message opens, so you can act quickly to nurture leads and close deals faster." image={enrich}/>
      <Manage subtitle="Get real-time campaign insights" text="No more guesswork—track key LinkedIn outreach metrics like connection rates, reply rates, and engagement, directly from your Salesforce dashboard. Monitor campaign performance in real-time and make data-driven decisions to optimize your outreach strategy." image={manage}/>
      <Tools />
      <Grad value="Unify your marketing & sales efforts today" text="Power up your pipeline with We-Connect and Salesforce"/>
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
