import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Content from '../HubSpot/Benefits/style'
export default function Overview(){
return(
<Content>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xxl-12 col-xl-12 col-lg-12">
        <Content.Content>
          
          <Content.ContentBox>
            <Content.TitleSmall as="h3">Simplify LinkedIn outreach workflows with a Pipedrive integration.</Content.TitleSmall>
            <Content.Text>• <b>Contact management</b>: Align lead data and buyer journeys with automated syncing with Pipedrive.</Content.Text>
            <Content.Text>• <b>Campaign reporting</b>: Track key outreach metrics to refine your messaging and campaigns.</Content.Text>
            <Content.Text>• <b>Real-time notifications</b>: Automate contact updates to maintain accurate and actionable lead databases.</Content.Text>
            <Content.Text>• <b>Automated workflows</b>: Streamline workflows by managing LinkedIn outreach directly from Pipedrive.</Content.Text>
          </Content.ContentBox>
          
        </Content.Content>
      </Col>
    </Row>
  </Container>
</Content>

)
}