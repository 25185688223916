import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import HeroSection from "~sections/LandingPages/Pipedrive/HeroSection"
import { Helmet } from "react-helmet"
import Manage from "~sections/LandingPages/Salesforce/Benefits/manage"
import Measure from "~sections/LandingPages/Salesforce/Benefits/Measure"
import Track from "~sections/LandingPages/Salesforce/Benefits/track"
import Enrich from "~sections/LandingPages/Salesforce/Benefits/enrich"
import Overview from "~sections/LandingPages/Pipedrive/overview"
import Tools from "~sections/LandingPages/Pipedrive/integration-tools"
import Grad from "~sections/LandingPages/Grad/grad"
import img1 from "~assets/image/media/Pipedrive/pic1.png";
import img2 from "~assets/image/media/Pipedrive/pic2.png";
import img3 from "~assets/image/media/Pipedrive/pic3.png";
import img4 from "~assets/image/media/Pipedrive/pic1.png";

const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Pipedrive() {
  return (
    <>
    <Helmet>
    <title>We-Connect | Pipedrive</title>
    <meta name="description" content=""/>
    <meta property="og:title" content=""/>
    <meta property="og:description" content=""/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content=""/>

    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content=""/>
    <meta property="twitter:description" content=""/>
    <meta property="twitter:image" content=""/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <HeroSection />
      <Overview />
      <Measure title="Integration features" subtitle="Monitor campaign analytics at a glance" image={img1} text="Track connection acceptance rates, replies, link clicks, and other outreach KPIs in Pipedrive to refine your messaging and improve conversions. Bring visibility into each sales rep's campaign performance, identifying winning strategies and areas of improvement."/>
      <Track subtitle="Automate lead enrichment on specific actions" image={img2} text="Sync LinkedIn contact data into Pipedrive based on specific campaign actions, including: accepted connections, replies, or clicks on an email link. Ensure your entire team is working with the most up-to-date contact information."/>
      <Enrich subtitle="Optimized sales workflows and notifications" image={img3} text="Automate tedious tasks like lead tracking and campaign management with real-time notifications that update sales teams on pipeline progress. Remain in alignment when syncing buyer journeys between We-Connect and Pipedrive."/>
      <Manage subtitle="Unified campaign management (coming soon)" image={img4} text="Design, manage, and track your LinkedIn campaigns directly from Pipedrive. Build personalized multi-channel LinkedIn and email outreach sequences that drive engagement with your LinkedIn profile."/>
      <Tools />
      <Grad value="Simplified lead management across all channels" text="Integrate with Pipedrive CRM today"/>
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
