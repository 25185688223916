import React, { useState } from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import { Modal, ModalContent } from "./Modal";
import Content from '../../../Sales/Styles/styleTwo'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';

export default function Measure({subtitle, text, image}){
  const [isOpen, setIsopen] = useState(false);
  const showModal = () => setIsopen((prev) => !prev);
return(
<Content>
  
  <Container>
    <Content.Inner>
    <Content.TitleSmall as="h3" className="text-center">Integration features</Content.TitleSmall>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
      
        <Content.TextBlock>
          <Content.SubTitle as="h2">{subtitle}</Content.SubTitle>

          <Content.Text> <SuperTag value={text}/> </Content.Text>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
      <Modal onOpen={showModal}>
        <Content.Image className="holder" ml="40px"> 
        <img width="660" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
        </Modal>
        {isOpen && (
        <ModalContent onClose={() => setIsopen(false)}>
          <img className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </ModalContent>
      )}
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>


)
}